// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatInput_container__BN-0m {
  height: 30px;
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #ffffff;
}

.ChatInput_input__DrJuk {
  flex: 1 1;
  padding: 8px;
  border: none;
  outline: none;
}

.ChatInput_button__MqzH1 {
  margin-left: 8px;
  padding: 8px;
  background-color: #686699;
  color: #ffffff;
  border: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatInput/ChatInput.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,SAAO;EACP,YAAY;EACZ,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".container {\n  height: 30px;\n  display: flex;\n  align-items: center;\n  padding: 8px;\n  background-color: #ffffff;\n}\n\n.input {\n  flex: 1;\n  padding: 8px;\n  border: none;\n  outline: none;\n}\n\n.button {\n  margin-left: 8px;\n  padding: 8px;\n  background-color: #686699;\n  color: #ffffff;\n  border: none;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ChatInput_container__BN-0m`,
	"input": `ChatInput_input__DrJuk`,
	"button": `ChatInput_button__MqzH1`
};
export default ___CSS_LOADER_EXPORT___;
