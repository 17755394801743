const config = {
     apiGreeting: 'https://poxagpt_backend.soselab.tw/greeting',
     apiChat: 'https://poxagpt_backend.soselab.tw/chat',
    // apiGreeting: 'http://127.0.0.1:5000/greeting',
    // apiChat: 'http://127.0.0.1:5000/chat',
    // apiHistory: 'https://kmamiz-bot-gpt.soselab.tw/api/history',
    // apiUser: 'https://kmamiz-bot-gpt.soselab.tw/api/user',
};

export default config;
