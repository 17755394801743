// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatApp_chatApp__mq\\+hu {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
  
.ChatApp_notFullScreen__9nZFD {
  display: flex;
  flex-direction: column;
  transform: translateX(0);
  margin-right: 16px;
  width: 300px;
  height: 50vh;
}
  
.ChatApp_fullScreen__4nRoi {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh; /* 100% 螢幕高度 */
}
  
.ChatApp_chatIcon__Nzvua {
  width: 30px;
  height: 30px;
  background-color: #8da3f3;
  color: #ffffff;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  bottom: 16px;
  right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatApp/ChatApp.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,QAAQ;EACR,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,aAAa,EAAE,cAAc;AAC/B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,YAAY;EACZ,WAAW;AACb","sourcesContent":[".chatApp {\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  background-color: #ffffff;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n}\n  \n.notFullScreen {\n  display: flex;\n  flex-direction: column;\n  transform: translateX(0);\n  margin-right: 16px;\n  width: 300px;\n  height: 50vh;\n}\n  \n.fullScreen {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh; /* 100% 螢幕高度 */\n}\n  \n.chatIcon {\n  width: 30px;\n  height: 30px;\n  background-color: #8da3f3;\n  color: #ffffff;\n  border-radius: 50%;\n  padding: 8px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  position: fixed;\n  bottom: 16px;\n  right: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatApp": `ChatApp_chatApp__mq+hu`,
	"notFullScreen": `ChatApp_notFullScreen__9nZFD`,
	"fullScreen": `ChatApp_fullScreen__4nRoi`,
	"chatIcon": `ChatApp_chatIcon__Nzvua`
};
export default ___CSS_LOADER_EXPORT___;
