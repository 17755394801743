// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatHeader_container__7vaKd {
    height: 30px;
    background-color: #686699;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
}

.ChatHeader_left__pT95n {
    display: flex;
    align-items: center;
}

.ChatHeader_logo__Frjtc {
    font-weight: bold;
    font-size: 18px;
    margin-left: 8px;
}

.ChatHeader_right__YIufd {
    display: flex;
    align-items: center;
}

.ChatHeader_button__Dm29Y {
    background: none;
    background-color: #686699;
    border: none;
    cursor: pointer;
    margin-left: 8px;
}

.ChatHeader_icon__SLz8l {
    color: white;
    width: 20px;
    height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatHeader/ChatHeader.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container {\n    height: 30px;\n    background-color: #686699;\n    color: #ffffff;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 8px 16px;\n}\n\n.left {\n    display: flex;\n    align-items: center;\n}\n\n.logo {\n    font-weight: bold;\n    font-size: 18px;\n    margin-left: 8px;\n}\n\n.right {\n    display: flex;\n    align-items: center;\n}\n\n.button {\n    background: none;\n    background-color: #686699;\n    border: none;\n    cursor: pointer;\n    margin-left: 8px;\n}\n\n.icon {\n    color: white;\n    width: 20px;\n    height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ChatHeader_container__7vaKd`,
	"left": `ChatHeader_left__pT95n`,
	"logo": `ChatHeader_logo__Frjtc`,
	"right": `ChatHeader_right__YIufd`,
	"button": `ChatHeader_button__Dm29Y`,
	"icon": `ChatHeader_icon__SLz8l`
};
export default ___CSS_LOADER_EXPORT___;
